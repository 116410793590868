/* Button styling */

body {
  -webkit-print-color-adjust: exact !important;
}

.btn-primary {
  background-color: #6379fd;
  transition: all 0.5s;
}

.btn-danger {
  background-color: #ff5264;
  transition: all 0.5s;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cus-nav-lk .nav-link {
  display: block;
  padding: 0.5rem 0.39rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

.version-number {
  /* position: absolute; */
  margin-bottom: 0px;
  text-align: center;
  padding-top: 2px;
  font-size: 14px;
  bottom: 0;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  margin-top: 30px;
}

/****/

.tody_doctors .owl-item .item {
  /* min-height: 385px !important; */
}

.pie_chart_sec {
  width: 100%;
}

.pie_chart_sec div {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.inner_tab_pan_scroll .filter-drop-down-chart {
  height: 36px !important;
}

.inner_tab_pan_scroll .filter-drop-down {
  height: 36px !important;
}

.date_boxpg {
  /* margin: -1px 22px 20px !important; */
}

.date_appoin_tdh {
  /* margin: -1px 0px 0px !important; */
}

@media only screen and (max-width: 1500px) {
  /* .modal-dialog {
        max-width: 500px !important;
    } */
}

@media only screen and (max-width: 1270px) {
  .appoinment_panel .multiple_appoinment_inner_tab,
  .appoinment_panel .multiple_inner_tab {
    min-height: 200 !important;
  }
  .second_head {
    margin-top: 0 !important;
  }
  .alert.alert-light {
    position: static;
  }
}

@media only screen and (max-width: 1000px) {
  .filter-drop-down-chart {
    position: static !important;
    margin: 25px auto;
    height: 36px !important;
  }
  .filter-drop-down {
    position: static !important;
    margin: 25px auto;
    height: 36px !important;
  }
}

@media only screen and (max-width: 500px) {
  .morning {
    display: block;
    float: none;
    position: absolute;
    top: 12px;
    right: 0;
  }
  .date_adjustar {
    margin-bottom: 10px;
  }
  .avail_timeupdate {
    padding: 20px 10px 20px;
  }
  .avail_time .booking-queue-checkbox {
    display: block;
    position: absolute;
    top: 5px;
    right: 0;
  }
  .avail_time .booking-queue-checkbox:last-child {
    top: 35px;
  }
}

.bs-timepicker-field {
  margin-bottom: 0px !important;
}

.modal-footer .btn_sec_brek {
  position: static;
}

.filter-drop-down-chart,
.filter-drop-down-lineChart {
  font-weight: bold;
}

.beau_box p {
  white-space: nowrap;
}

@media only screen and (max-width: 400px) {
  .profile_editbts {
    width: 230px !important;
    display: inline-block !important;
    float: none !important;
  }
}

@media only screen and (max-width: 1270px) {
  .alert.alert-light,
  .queue-index {
    position: static;
  }
}

.const-name-be {
  font-size: 18px !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
  font-weight: normal !important;
  margin-top: 7px !important;
  opacity: 0.8 !important;
}

.f-t-h {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.second_head {
  /* left: 0; */
  overflow: unset !important;
  /* margin-top: 0px; */
  width: calc(100% - 110px);
  height: 85px;
  padding-top: 5px;
  position: fixed;
  top: 80px;
  left: 110px;
  z-index: 15;
  overflow: hidden;
  text-align: center;
}

@media only screen and (max-width: 1270px) {
  .second_head {
    position: static;
    height: auto;
    padding-top: 0;
    float: none;
  }
  header {
    border-bottom: 0;
  }
}

.countHidden {
  display: none !important;
}

/* html, body { height: 100%; } */
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

/* .consultant-border-color {
}
.customer-border-color {
} */

@-webkit-keyframes zoomInFix {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
    pointer-events: none;
  }

  50% {
    opacity: 0.5;
    pointer-events: unset;
  }
}

@keyframes zoomInFix {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
    pointer-events: none;
  }

  50% {
    opacity: 0.5;
    pointer-events: unset;
  }
}

.zoomInFix {
  -webkit-animation-name: zoomInFix;
  animation-name: zoomInFix;
}

.unset-animation {
  animation-name: unset !important;
}



/* 
#booking-ticket.help-container {
  width: 100%;
  height: auto;
  background-color: #9a9a9a;
  position: relative;
  min-height: 100vh;
}

#booking-ticket .list-container {
  background-color: #fff;
  width: 25%;
  position: absolute;
  top: 45%; 
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); 
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

#booking-ticket .succes_cofbx {
  width: 40%;
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -80%);
  min-height: 600px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  border-radius: 20px 20px 4px 4px;
}

@media only screen and (max-width: 1400px) {
  #booking-ticket .succes_cofbx.res {
    width: 55%;
  }
}

@media only screen and (max-width: 880px) {
  #booking-ticket .succes_cofbx.res {
    width: 80%;
  }
}

@media only screen and (max-width: 572px) {
  #booking-ticket .succes_cofbx.res {
    width: 95%;
  }
}

#booking-ticket .confirmimgbox_inntab {
  font-size: 21px;
}

#booking-ticket .increase-margin-top {
  margin-top: 65px;
}

#booking-ticket .twisterInDown {
  animation-name: twisterInDown;
  animation-delay: 100ms;
}

#booking-ticket .confirmimgbox_inntab {
  font-size: 18px;
}

#booking-ticket .clickable-btn {
  position: absolute;
  z-index: 999;
  top: 15px;
  right: 15px;
}

@media only screen and (max-width: 480px) {
  #booking-ticket .clickable-btn button {
    display: block;
  }
}

app-booking-ticket #download {
  display: none;
}

#booking-ticket .btn.btn-light {
  margin-right: 8px;
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

#booking-ticket .print-div-container {
  position: relative;
}

#booking-ticket .divider-line {
  display: inline-block;
  width: 100%;
  position: relative;
}

#booking-ticket .divider-line .line {
  border: 0;
  border-top: 2px dashed #9a9a9a;
}

#booking-ticket .divider-line .left,
#booking-ticket .divider-line .right {
  position: absolute;
  top: -10px;
  width: 50px;
  height: 50px;
  background: #9a9a9a;
  z-index: 4;
  border-radius: 50%;
}

#booking-ticket .divider-line .left {
  left: -25px;
}

#booking-ticket .divider-line .right {
  right: -25px;
}

@media only screen and (max-width: 501px) {
  #booking-ticket .divider-line .left {
    left: -45px;
  }
  #booking-ticket .divider-line .right {
    right: -45px;
  }
}

#booking-ticket .bottom-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 260px;
  align-content: space-around;
}


#booking-ticket .business-name-icons {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

#booking-ticket .business-name-icons img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

#booking-ticket .business-name-icons h3 {
  margin-top: 5px;
  margin-bottom: 20px;
}

#booking-ticket .ticket-end {
  width: 100%;
  position: relative;
  top: 56px;
  height: 58px;
}

#booking-ticket .ticket-end img {
  width: 100%;
}
#booking-ticket .tit-text {
  margin-top: 0;
}

#booking-ticket .tit-text::after {
  content: unset !important;
  display: none;
}

#booking-ticket .print-div {
  position: relative;
  width: 900px;
  display: block;
  height: auto;
  background-color: #9a9a9a;
  padding: 30px 30px 30px 30px;
  z-index: -1;
  left: -10000px;
}

#booking-ticket .print-div .succes_cofbx {
  position: relative;
  width: 800px;
  left: 0;
  top: 0;
  transform: translate(0%, 0%);
  margin: 0 auto;
  margin-bottom: 30px;
}



#booking-ticket .invalid-container {
  position: relative;
  transform: translate(-50%, 10%);
  border-radius:20px
}

.custom-modal-width {
  max-width: 1200px;
}

.panel_edithrt.profile ngx-doc-viewer .container {
  padding: 0;
}

.ndfHFb-c4YZDc-Wrql6b {
  display: none;
}

@media print{
   
  .business-name-icons .businessImage {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover !important;  
    align-items: center !important;
    position: relative !important;
    left: 35px !important;
  }

  .default-image{
    left: 38px !important ;
  }

  .business-name-icons{
    margin-bottom: 35px !important;
    align-items: center !important;
  }

  .business-name{
    top: 50px !important;
    text-align: center !important;
    right: 33px !important;
  }


 .succes_cofbx {
    width:610px !important;
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 800px;
    transform: translate(-50%, -80%);
    min-height: 600px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    border-radius: 20px 20px 5px 5px !important;
  }
  
  

 .confirmimgbox_inntab {
    font-size: 21px !important;
  }
  
  .increase-margin-top {
    margin-top: 65px !important;
  }
  

  .confirmimgbox_inntab {
    font-size: 18px !important;
  }
  

  .divider-line {
    display: inline-block;
    width: 100%;
    position: relative;
  }
  
  .divider-line .line {
    border: 0;
    border-top: 1px dashed #9a9a9a;
  }
  
  .divider-line .left,
  .divider-line .right {
    position: absolute;
    top: -10px;
    width: 50px;
    height: 50px;
    background: #9a9a9a;
    z-index: 4;
    border-radius: 50%;
  }
  
  .divider-line .left {
    left: -25px;
  }
  
   .divider-line .right {
    right: -25px;
  }
  
  
  
  .bottom-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 260px;
    align-content: space-around;
  }
  
 
  .business-name-icons {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  
  
  .business-name-icons h3 {
    margin-top: 5px;
    margin-bottom: 20px;
  }
  
  .ticket-end {
    width: 100%;
    position: relative;
    top: 57px;
    height: 58px;
  }
  
  .ticket-end img {
    width: 100%;
  }

  .tit-text {
    margin-top: 0;
  }
  
  .tit-text::after {
    content: unset;
  }
  
  
  .print-div .succes_cofbx {
    position: relative;
    width: 800px;
    left: 0;
    top: 0;
    transform: translate(0%, 0%);
    margin: 0 auto;
    margin-bottom: 30px;
   
  }

  .print-div-body{
    width: 100%;
    height: 100%;
    background-color: #9a9a9a !important;
    background: #9a9a9a !important;
  }
  
  .tit-text::after {
    content: unset !important;
    display: none;
  }

} */
